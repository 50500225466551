
import './App.css';
import About from "./components/Home Page/About.jsx"
import Shows from "./components/Shows Page/Shows.jsx"
import Songs from "./components/Home Page/Songs.jsx"
import Home from './components/Home Page/Home';
import { Route, Routes } from "react-router-dom";
import { FaSpotify, FaInstagram, FaFacebook, FaTiktok, FaYoutube, FaSoundcloud } from "react-icons/fa";
import { SiApplemusic } from "react-icons/si";
import { IoArrowUpCircleSharp } from "react-icons/io5";
import {  useEffect, useState } from 'react';
import Modal from './components/Modal';
import { HelmetProvider } from 'react-helmet-async';
import Photos from "./components/Photos Page/Photos.jsx"
import Films from './components/Films/Films.jsx';
import Sidebar from './components/Sidebar.jsx';
import Footer from "./components/Home Page/Footer.jsx"
import Tracks from './components/Tracks.jsx';
import Work from './components/Films/Work.jsx';
import Directed from './components/Films/Directed.jsx';
import Produced from './components/Films/Produced.jsx';
import ShortFilms from './components/Films/ShortFilms.jsx';
import MusicVideos from './components/Films/MusicVideos.jsx';
import ScrollToTop from './components/ScrollToTop.jsx';
import { useLocation } from 'react-router-dom';
import Lyrics from './components/Lyrics.jsx';



const Layout = ({ isImageLoaded, children, setIsMusicExpanded, setIsFilmExpanded, isMusicExpanded, isFilmExpanded, isBurgerClicked, setIsBurgerClicked, isNotHome }) => (
  <div className='wrapper'>
    {/* Burger Icon */}
    <div className="menu-icon" onClick={() => setIsBurgerClicked(!isBurgerClicked)}>
      <input className="menu-icon__cheeckbox" type="checkbox" />
      <div>
        <span className={isMusicExpanded || isNotHome ? '' : 'film'}></span>
        <span className={isMusicExpanded || isNotHome ? '' : 'film'}></span>
      </div>
    </div>
    {/* Burger Icon */}
    <Sidebar setIsMusicExpanded={setIsMusicExpanded} setIsFilmExpanded={setIsFilmExpanded} isBurgerClicked={isBurgerClicked} isMusicExpanded={isMusicExpanded} isFilmExpanded={isFilmExpanded} />
    <ul className="songs-socials">
      <li className={isMusicExpanded || isNotHome ? "" : 'film'}><a href="https://open.spotify.com/artist/4CaDROYjZpUFMtSvwYv9kY" target='_blank' rel="noreferrer"><FaSpotify /></a></li>
      <li className={isMusicExpanded || isNotHome ? "" : 'film'}><a href="https://www.instagram.com/benscharlin/" target='_blank' rel="noreferrer"><FaInstagram /></a></li>
      <li className={isMusicExpanded || isNotHome ? "" : 'film'}><a href="https://www.facebook.com/p/Ben-Scharlin-Music-100093993866136/" target='_blank' rel="noreferrer"><FaFacebook /></a></li>
      <li className={isMusicExpanded || isNotHome ? "" : 'film'}><a href="https://www.tiktok.com/@benscharlin" target='_blank' rel="noreferrer"><FaTiktok /></a></li>
      <li className={isMusicExpanded || isNotHome ? "" : 'film'}><a href="https://www.youtube.com/@benscharlinmusic" target='_blank' rel="noreferrer"><FaYoutube /></a></li>
      <li className={isMusicExpanded || isNotHome ? "" : 'film'}><a href="https://soundcloud.com/ben-scharlin" target='_blank' rel="noreferrer"><FaSoundcloud /></a></li>
      <li className={isMusicExpanded || isNotHome ? "" : 'film'}><a href="https://music.apple.com/us/artist/ben-scharlin/1625071737" target='_blank' rel="noreferrer"><SiApplemusic /></a></li>
    </ul> 
    <Modal />
    {children}
    {isImageLoaded.film && isImageLoaded.music || isNotHome ? <Footer /> : ""}


  </div>
);

const MusicContent = () => {
  return (
    <>
      <About />
      <Songs />
    </>
  )

}

const FilmContent = () => {
  return (
    <>
      <Films />
    </>
  )
}


function App() {
  const [showScrollUp, setShowScrollUp] = useState(false);
  const [isMusicExpanded, setIsMusicExpanded] = useState(false);
  const [isFilmExpanded, setIsFilmExpanded] = useState(false);
  const [isBurgerClicked, setIsBurgerClicked] = useState(false);
  const [isNotHome, setIsNotHome] = useState(false)
  const [isImageLoaded, setIsImageLoaded] = useState({ music: false, film: false })
  const [progress, setProgress] = useState({ music: 0, film: 0 });
  const [overallProgress, setOverallProgress] = useState(0)

  const calculateOverallProgress = (newProgress) => {
    const totalProgress = (newProgress.music + newProgress.film) / 2;
    setOverallProgress(totalProgress);
  };


  const handleScrollDown = () => {

    const viewportHeight = window.innerHeight;

    const currentScrollPosition = window.scrollY || window.pageYOffset;

    const targetScrollPosition = currentScrollPosition + viewportHeight;

    window.scrollTo({
      top: targetScrollPosition,
      behavior: 'smooth',
    });
  };
  const handleScrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const isTop = window.scrollY === 0 || window.pageYOffset === 0;
      setShowScrollUp(!isTop);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  const location = useLocation()



  useEffect(() => {
    const pathnames = ['shows', 'photos', 'tracks', 'work', 'directed', 'produced', 'short-films', 'music-videos']
    const currentPath = location.pathname.substring(1);
    const indexOfSecondSlash = currentPath.indexOf('/', 1); 
    const cleanPath = indexOfSecondSlash !== -1 ? currentPath.substring(0, indexOfSecondSlash) : currentPath;

    const isPathMatched = pathnames.includes(cleanPath);
    setIsNotHome(isPathMatched);
}, [location.pathname])



  const helmetContext = {};

  return (
    <div className='App' >
      
      <HelmetProvider context={helmetContext}>


      <ScrollToTop>

        <Routes>


          <Route path="/"
            element={

              <Layout isImageLoaded={isImageLoaded} setIsFilmExpanded={setIsFilmExpanded} setIsMusicExpanded={setIsMusicExpanded} isMusicExpanded={isMusicExpanded} isFilmExpanded={isFilmExpanded} isBurgerClicked={isBurgerClicked} setIsBurgerClicked={setIsBurgerClicked}>
                
                <div className={`Load ${isImageLoaded.film && isImageLoaded.music ? 'hidden' : ''}`}>
                  <h1>BEN SCHARLIN<span className="ellipsis"></span></h1>
                  <div aria-hidden='true' className='loading-bar'><div className='loading-bg' style={{ width: `${overallProgress}%`}}/></div>
                  <p>{Math.trunc(overallProgress)}%</p>
                </div>
                {isFilmExpanded || isMusicExpanded ? <div className={isMusicExpanded ? "scroll" : 'scroll film'} onClick={handleScrollDown}><div className={isMusicExpanded ? "scroll-icon" : 'scroll-icon film'} aria-hidden='true'></div></div> : ""}
                {showScrollUp && window.scrollY > 0 && (
                  <div className={isMusicExpanded ? 'scroll-up' : 'scroll-up film'} onClick={handleScrollUp}><IoArrowUpCircleSharp /></div>
                )}
                <Home calculateOverallProgress={calculateOverallProgress} progress={progress} setProgress={setProgress} setIsImageLoaded={setIsImageLoaded} isMusicExpanded={isMusicExpanded} setIsMusicExpanded={setIsMusicExpanded} isFilmExpanded={isFilmExpanded} setIsFilmExpanded={setIsFilmExpanded} />
                {isMusicExpanded && <MusicContent />}
                {isFilmExpanded && <FilmContent />}


              </Layout>

            } />
          <Route path="/shows"
            element={
              <Layout isImageLoaded={isImageLoaded} setIsFilmExpanded={setIsFilmExpanded} setIsMusicExpanded={setIsMusicExpanded} isNotHome={isNotHome} isMusicExpanded={isMusicExpanded} isFilmExpanded={isFilmExpanded} isBurgerClicked={isBurgerClicked} setIsBurgerClicked={setIsBurgerClicked}>
                {isFilmExpanded || isMusicExpanded ? <div className="scroll" onClick={handleScrollDown}><div className="scroll-icon" aria-hidden='true'></div></div> : ""}
                {showScrollUp && window.scrollY > 0 && (
                  <div className='scroll-up' onClick={handleScrollUp}><IoArrowUpCircleSharp /></div>
                )}
                <Shows />


              </Layout>
            } />
          <Route path="/photos"
            element={
              <Layout isImageLoaded={isImageLoaded} setIsFilmExpanded={setIsFilmExpanded} setIsMusicExpanded={setIsMusicExpanded} isNotHome={isNotHome} isMusicExpanded={isMusicExpanded} isFilmExpanded={isFilmExpanded} isBurgerClicked={isBurgerClicked} setIsBurgerClicked={setIsBurgerClicked}>
                {isFilmExpanded || isMusicExpanded ? <div className="scroll" onClick={handleScrollDown}><div className="scroll-icon" aria-hidden='true'></div></div> : ""}
                {showScrollUp && window.scrollY > 0 && (
                  <div className='scroll-up' onClick={handleScrollUp}><IoArrowUpCircleSharp /></div>
                )}
                <Photos />


              </Layout>
            } />
          <Route path="/tracks"
            element={
              <Layout isImageLoaded={isImageLoaded} setIsFilmExpanded={setIsFilmExpanded} setIsMusicExpanded={setIsMusicExpanded} isNotHome={isNotHome} isMusicExpanded={isMusicExpanded} isFilmExpanded={isFilmExpanded} isBurgerClicked={isBurgerClicked} setIsBurgerClicked={setIsBurgerClicked}>
                {isFilmExpanded || isMusicExpanded ? <div className="scroll" onClick={handleScrollDown}><div className="scroll-icon" aria-hidden='true'></div></div> : ""}
                {showScrollUp && window.scrollY > 0 && (
                  <div className='scroll-up' onClick={handleScrollUp}><IoArrowUpCircleSharp /></div>
                )}
                <Tracks />


              </Layout>
            } />
            <Route path="/tracks/:title"
            element={
              <Layout isImageLoaded={isImageLoaded} setIsFilmExpanded={setIsFilmExpanded} setIsMusicExpanded={setIsMusicExpanded} isNotHome={isNotHome} isMusicExpanded={isMusicExpanded} isFilmExpanded={isFilmExpanded} isBurgerClicked={isBurgerClicked} setIsBurgerClicked={setIsBurgerClicked}>
                {isFilmExpanded || isMusicExpanded ? <div className="scroll" onClick={handleScrollDown}><div className="scroll-icon" aria-hidden='true'></div></div> : ""}
                {showScrollUp && window.scrollY > 0 && (
                  <div className='scroll-up' onClick={handleScrollUp}><IoArrowUpCircleSharp /></div>
                )}
                <Lyrics />


              </Layout>
            } />

          <Route path="/work"
            element={
              <Layout isImageLoaded={isImageLoaded} setIsFilmExpanded={setIsFilmExpanded} setIsMusicExpanded={setIsMusicExpanded} isNotHome={isNotHome} isMusicExpanded={isMusicExpanded} isFilmExpanded={isFilmExpanded} isBurgerClicked={isBurgerClicked} setIsBurgerClicked={setIsBurgerClicked}>
                {isFilmExpanded || isMusicExpanded ? <div className="scroll" onClick={handleScrollDown}><div className="scroll-icon" aria-hidden='true'></div></div> : ""}
                {showScrollUp && window.scrollY > 0 && (
                  <div className='scroll-up' onClick={handleScrollUp}><IoArrowUpCircleSharp /></div>
                )}
                <Work />


              </Layout>
            } />
          <Route path="/directed"
            element={
              <Layout isImageLoaded={isImageLoaded} setIsFilmExpanded={setIsFilmExpanded} setIsMusicExpanded={setIsMusicExpanded} isNotHome={isNotHome} isMusicExpanded={isMusicExpanded} isFilmExpanded={isFilmExpanded} isBurgerClicked={isBurgerClicked} setIsBurgerClicked={setIsBurgerClicked}>
                {isFilmExpanded || isMusicExpanded ? <div className="scroll" onClick={handleScrollDown}><div className="scroll-icon" aria-hidden='true'></div></div> : ""}
                {showScrollUp && window.scrollY > 0 && (
                  <div className='scroll-up' onClick={handleScrollUp}><IoArrowUpCircleSharp /></div>
                )}
                <Directed />


              </Layout>
            } />
          <Route path="/produced"
            element={
              <Layout isImageLoaded={isImageLoaded} setIsFilmExpanded={setIsFilmExpanded} setIsMusicExpanded={setIsMusicExpanded} isNotHome={isNotHome} isMusicExpanded={isMusicExpanded} isFilmExpanded={isFilmExpanded} isBurgerClicked={isBurgerClicked} setIsBurgerClicked={setIsBurgerClicked}>
                {isFilmExpanded || isMusicExpanded ? <div className="scroll" onClick={handleScrollDown}><div className="scroll-icon" aria-hidden='true'></div></div> : ""}
                {showScrollUp && window.scrollY > 0 && (
                  <div className='scroll-up' onClick={handleScrollUp}><IoArrowUpCircleSharp /></div>
                )}
                <Produced />


              </Layout>
            } />
          <Route path="/short-films"
            element={
              <Layout isImageLoaded={isImageLoaded} setIsFilmExpanded={setIsFilmExpanded} setIsMusicExpanded={setIsMusicExpanded} isNotHome={isNotHome} isMusicExpanded={isMusicExpanded} isFilmExpanded={isFilmExpanded} isBurgerClicked={isBurgerClicked} setIsBurgerClicked={setIsBurgerClicked}>
                {isFilmExpanded || isMusicExpanded ? <div className="scroll" onClick={handleScrollDown}><div className="scroll-icon" aria-hidden='true'></div></div> : ""}
                {showScrollUp && window.scrollY > 0 && (
                  <div className='scroll-up' onClick={handleScrollUp}><IoArrowUpCircleSharp /></div>
                )}
                <ShortFilms />


              </Layout>
            } />
          <Route path="/music-videos"
            element={
              <Layout isImageLoaded={isImageLoaded} setIsFilmExpanded={setIsFilmExpanded} setIsMusicExpanded={setIsMusicExpanded} isNotHome={isNotHome} isMusicExpanded={isMusicExpanded} isFilmExpanded={isFilmExpanded} isBurgerClicked={isBurgerClicked} setIsBurgerClicked={setIsBurgerClicked}>
                {isFilmExpanded || isMusicExpanded ? <div className="scroll" onClick={handleScrollDown}><div className="scroll-icon" aria-hidden='true'></div></div> : ""}
                {showScrollUp && window.scrollY > 0 && (
                  <div className='scroll-up' onClick={handleScrollUp}><IoArrowUpCircleSharp /></div>
                )}
                <MusicVideos />


              </Layout>
            } />


        </Routes>
      </ScrollToTop>
      </HelmetProvider>
    </div>
  );
}

export default App;
