import React, { useCallback, useEffect, useRef, useState } from 'react';
import '../styles/Tracks.css';
import { FaSpotify, FaPlay, FaPause } from 'react-icons/fa';
import tracksData from "../data/Tracks.js";
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import placeHolder from "../assets/placeholder.png"
import { Helmet } from 'react-helmet-async';

const formatTime = (time) => {
    if (time && !isNaN(time)) {
        const minutes = Math.floor(time / 60);
        const formatMinutes =
            minutes < 10 ? `0${minutes}` : `${minutes}`;
        const seconds = Math.floor(time % 60);
        const formatSeconds =
            seconds < 10 ? `0${seconds}` : `${seconds}`;
        return `${formatMinutes}:${formatSeconds}`;
    }
    return '00:00';
};

const ProgressBar = ({ audioRef, timeProgress, duration, progressBarRef }) => {

    const handleProgressChange = () => {
        audioRef.current.currentTime = progressBarRef.current.value;
    };
    return (
        <div className="progress">
            <span className="time current">{formatTime(timeProgress)}</span>
            <input
                type="range"
                ref={progressBarRef}
                defaultValue="0"
                onChange={handleProgressChange}
            />

            <span className="time">{formatTime(duration)}</span>
        </div>
    );
};

export const Track = ({ track }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [timeProgress, setTimeProgress] = useState(0);
    const [duration, setDuration] = useState(0);
    const progressBarRef = useRef();
    const audioRef = useRef();

    const togglePlayPause = () => {
        setIsPlaying((prev) => !prev);
    };

    const onLoadedMetadata = () => {
        setDuration(audioRef.current.duration);
        progressBarRef.current.max = audioRef.current.duration;
    };

    const repeat = useCallback(() => {
        const currentTime = audioRef.current.currentTime;
        setTimeProgress(currentTime);
        progressBarRef.current.value = currentTime;
        progressBarRef.current.style.setProperty(
            '--range-progress',
            `${(progressBarRef.current.value / duration) * 100}%`
        );
        requestAnimationFrame(repeat);
    }, [duration]);

    useEffect(() => {
        if (isPlaying) {
            audioRef.current.play();
            requestAnimationFrame(repeat);
        } else {
            audioRef.current.pause();
        }
    }, [isPlaying, repeat]);

    return (
        <div className="track">
            
            <div className="track-details">
                <div className="track__wrapper">
                    <LazyLoadImage src={track.image} alt={track.title} effect='blur' placeholderSrc={placeHolder} loading='lazy'/>
                    <div className="track__details-text">
                        <h4>{track.title}</h4>
                        <p>{track.artist}</p>
                        <div className="track-buttons">
                            <a href={track.spotify} target='_blank' rel="noreferrer"><button className='spotify-btn'><FaSpotify /></button></a>
                            {/* <button className='share-btn'><span><FaRegShareSquare /></span>Share</button> */}
                        </div>
                        <h5 className="release-date">RELEASED ON {track.releaseDate}</h5>
                    </div>
                </div>
                <div className="audio-player">
                <button onClick={togglePlayPause}>
                    {isPlaying ? <FaPause/> : <FaPlay/>}
                </button>
                <ProgressBar {...{ progressBarRef, audioRef, timeProgress, duration }} />
                <audio src={track.audio} ref={audioRef} onLoadedMetadata={onLoadedMetadata} />
                <Link to={`/tracks/${track.title.toLowerCase()}`}><button>Lyrics</button></Link>
                </div>
               
            </div>
        </div>
    );
};

const Tracks = () => {
    return (
        <div className="Tracks">
             <Helmet>
                <title>Song Tracks | Ben Scharlin</title>
                <meta name="description" content="Ben Scharlin's latest music tracks" />
                <link rel="canonical" href='/tracks' />
            </Helmet>
            <div className="tracks-content">
                <h1>Tracks</h1>
                {tracksData.map((track, index) => (
                    <Track key={index} track={track} />
                ))}
            </div>
        </div>
    );
};

export default Tracks;
