import React, { useEffect } from "react";
import "../../styles/Shows Page/Shows.css"
import { Helmet } from "react-helmet-async";


const Shows = () => {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://static.elfsight.com/platform/platform.js";
        script.setAttribute('data-use-service-core', '');
        script.defer = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className="Shows">
            <Helmet>
                <title>Shows | Ben Scharlin</title>
                <meta name="description" content="Ben Scharlin's upcoming shows" />
                <link rel="canonical" href='/shows' />
            </Helmet>
            <div className="shows-content">
                <div className="shows-text">
                    <h1>Shows</h1>
                    <a href="https://www.instagram.com/benscharlin/" target="_blank" rel="noreferrer"><button>Follow</button></a>

                </div>

                <div className="shows">

                    <div className="elfsight-app-4b7fb855-0f67-498f-b6fa-49855c191d11" data-elfsight-app-lazy></div>
                </div>
            </div>
        </div>
    )
}

export default Shows