import React from 'react'
import "../../styles/Home Page/Footer.css"
import { FaSpotify, FaInstagram, FaFacebook, FaTiktok, FaYoutube, FaSoundcloud } from "react-icons/fa";
import { SiApplemusic } from 'react-icons/si';
import { Link } from 'react-router-dom';
import CustomForm from '../CustomForm';
import MailchimpSubscribe from "react-mailchimp-subscribe";
const Footer = () => {
  
  const postUrl = `https://gmail.us17.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`
  return (
    <footer className="Footer">
      <div className="footer__content">
        <div className="first-column">
          <h1>BEN SCHARLIN</h1>
          <ul className="footer__socials">
            <li><a href="https://open.spotify.com/artist/4CaDROYjZpUFMtSvwYv9kY" target='_blank'><FaSpotify /></a></li>
            <li><a href="https://www.instagram.com/benscharlin/" target='_blank'><FaInstagram /></a></li>
            <li><a href="https://www.facebook.com/p/Ben-Scharlin-Music-100093993866136/" target='_blank'><FaFacebook /></a></li>
            <li><a href="https://www.tiktok.com/@benscharlin" target='_blank'><FaTiktok /></a></li>
            <li><a href="https://www.youtube.com/@benscharlinmusic" target='_blank'><FaYoutube /></a></li>
            <li><a href="https://soundcloud.com/ben-scharlin" target='_blank'><FaSoundcloud /></a></li>
            <li><a href="https://music.apple.com/us/artist/ben-scharlin/1625071737" target='_blank'><SiApplemusic /></a></li>
          </ul>

        </div>
        <div className="second-column">
          <h5>MUSIC</h5>
          <Link to="/shows">Shows</Link>
          <Link to="/photos">Photos</Link>
          <Link to="/tracks">Tracks</Link>

        </div>
        <div className="third-column">
        <h5>FILMS</h5>
        <Link to="/work">Film Works</Link>
          <Link to="/directed">Directed</Link>
          <Link to="/produced">Produced</Link>
          <Link to="/short-films">Short Films</Link>
          <Link to="/music-videos">Music Videos</Link>


        </div>
        <div className="fourth-column">
          <h5>NEWSLETTER</h5>
          <MailchimpSubscribe url={postUrl}
                render={({ subscribe, status, message }) => (
                    <CustomForm
                        status={status}
                        message={message}
                        onValidated={formData => subscribe(formData)}
                    />
                )} />
          

        </div>


      </div>
      <div className="footer__separator"/>
      <div className="footer__copyright">
        <p>
          BEN SCHARLIN 2024 © All Rights Reserved
        </p>
        <p>Developed by VC Developments</p>
      </div>

    </footer>
  )
}

export default Footer
