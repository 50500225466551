import React from "react";
import "../../styles/Films/Directed.css"
import film from "../../assets/film.mp4"
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
const Directed = () => {
    return (


        <div className="Directed">
            <Helmet>
                <title>Directed Films | Ben Scharlin</title>
                <meta name="description" content="All of Ben Scharlin's directed films." />
                <link rel="canonical" href='/directed' />
            </Helmet>
            <div className="directed-content">
                <h1>Directed</h1>
                <Link to="/work">Back home</Link>
                <div className="category-grid-container">
                    <div className="grid-item"><div className="grid-item">
                        <div className="video-overlay">
                            <p>Lines</p>
                        </div>
                        <video src={film} autoPlay alt="" playsInline/>
                    </div></div>
                    <div className="grid-item"><div className="grid-item">
                        <div className="video-overlay">
                            <p>Lines</p>
                        </div>
                        <video src={film} autoPlay alt="" playsInline/>
                    </div></div>
                </div>
            </div>

        </div>

    )
}

export default Directed