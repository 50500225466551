import React, { useEffect, useState } from 'react';

const CustomForm = ({ status, message, onValidated }) => {

    const [email, setEmail] = useState('');

    useEffect(() => {
        if(status === "success") clearFields();
      }, [status])
    
      const clearFields = () => {
     
        setEmail('');
      }
    const handleSubmit = (e) => {
        e.preventDefault();
        email &&
            email.indexOf("@") > -1 &&
            onValidated({
                EMAIL: email,

            });
    }


    return (
        <form className="custom-form" onSubmit={(e) => handleSubmit(e)}>
            <p>{status === "success" 
            ? "" : "Please enter your email to join Ben's newsletter!"}</p>
            {status === "sending" && (
                <p className='message sending'>
                    sending...
                </p>
            )}
            {status === "error" && (
                <p className='message error'
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
            {status === "success" && (
                <p className='message submit'
                   
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}

            <input
                label="Email"
                onChange={(e)=> setEmail(e.target.value)}
                type="email"
                value={email}
                placeholder="your@email.com"
                isrequired="true"
             
            />

            <button>Submit</button>
        </form>)
};

export default CustomForm