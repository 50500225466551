import React from "react";
import "../styles/Lyrics.css"
import tracksData from "../data/Tracks";
import { Track } from "./Tracks";
import { Helmet } from "react-helmet-async";
const Lyrics = () => {
    const fullURL = window.location.pathname;

    // Split the URL by '/'
    const urlParts = fullURL.split("/");

    // Get the last part of the array (which is "Epson")
    const desiredPart = urlParts[urlParts.length - 1];

    // Remove texts with percent from URL
    const decodedURL = decodeURIComponent(desiredPart);

    const result = decodedURL;
    const track = tracksData.filter((track) => track.title.toLowerCase() === result);
  
    return (
        <div className="Lyrics">
            <Helmet>
                <title>{track[0].title} Lyrics | Ben Scharlin</title>
                <meta name="description" content="Ben Scharlin's latest music tracks" />
                <link rel="canonical" href={`/tracks/${track[0].title}`} />
            </Helmet>
            <div className="lyrics-content">
                <h1>{track[0].title}</h1>
                <Track track = {track[0]}/>
                <span>{track[0].lyrics.map((lyric, key) => <p key={key}>{lyric}</p>) }   </span>
                
            </div>

        </div>
    )
}

export default Lyrics