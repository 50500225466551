import React from "react";
import "../../styles/Films/ShortFilms.css"
import film from "../../assets/film.mp4"
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const ShortFilms = () => {
    return (
        <div className="ShortFilms">
            <Helmet>
                <title>Short Films | Ben Scharlin</title>
                <meta name="description" content="All of Ben Scharlin's short films." />
                <link rel="canonical" href='/short-films' />
            </Helmet>
            <div className="film-content">
                <h1>Short Films</h1>
                <Link to ="/work">Back home</Link>
                <div className="category-grid-container">
                <div className="grid-item"><div className="grid-item">
                    <div className="video-overlay">
                        <p>Lines</p>
                    </div>
                    <video src={film} autoPlay alt="" playsInline/>
                </div></div>
                <div className="grid-item"><div className="grid-item">
                    <div className="video-overlay">
                        <p>Lines</p>
                    </div>
                    <video src={film} autoPlay alt="" playsInline/>
                </div></div>
                </div>
            </div>
        </div>
    )
}

export default ShortFilms