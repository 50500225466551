import React from "react";
import "../../styles/Films/Work.css"
import { Link } from "react-router-dom";
import video from "../../assets/film.mp4"
import { Helmet } from "react-helmet-async";
const Work = () => {
    return (
        <div className="Work">
            <Helmet>
                <title>Film Works | Ben Scharlin</title>
                <meta name="description" content="All of Ben Scharlin's films." />
                <link rel="canonical" href='/work' />
            </Helmet>
            <div className="work-content">
                <h1>Film Works</h1>
                <p className="work-p">Scroll down to see more</p>
                <div className="film-works">
                    <div className="directed-work">
                        <Link to="/directed">
                            <div className="work">
                                <video src={video} autoPlay playsInline/>
                                <div>
                                <p className="work-genre">Directed</p>
                                <p className="work-year">2021 - 2024</p>
                            </div>
                            </div>
                        </Link>
                    </div>

                    <div className="film-grid">
                        <Link to="/produced">
                            <div className="work">
                                <video src={video} autoPlay playsInline/>
                                <div>
                                <p className="work-genre">Produced</p>
                                <p className="work-year">2021 - 2024</p>
                            </div>
                            </div>
                        </Link>
                        <Link to="/short-films">
                            <div className="work">
                                <video src={video} autoPlay playsInline/>
                                <div>
                                <p className="work-genre">Short Films</p>
                                <p className="work-year">2021 - 2024</p>
                            </div>
                            </div>
                        </Link>


                    </div>
                    <div className="video-works">
                        <Link to="/music-videos">
                            <div className="work">
                                <video src={video} autoPlay playsInline/>
                                <div>
                                <p className="work-genre">Music Videos</p>
                                <p className="work-year">2021 - 2024</p>
                            </div>
                            </div>
                        </Link>

                    </div>


                </div>
            </div>
        </div>
    )
}

export default Work