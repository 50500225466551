import gravitate from "../../src/assets/background3.jpg"
import time from "../../src/assets/photos/ben7.JPG"
import likeLightning from "../../src/assets/photos/ben11.JPG"
import lines from "../../src/assets/background.JPG"
import theFall from "../../src/assets/background9.jpg"

export const songs = [
    {
        id: 1,
        title: "Gravitate (2023)",
        link: "/tracks/gravitate",
        image: gravitate

    },
    {
        id: 2,
        title: "Time (2023)",
        link: "/tracks/time",
        image: time

    },
    {
        id: 3,
        title: "Like Lightning (2022)",
        link: "/tracks/like%20lightning",
        image: likeLightning

    },
    {
        id: 4,
        title: "Lines (2022)",
        link: "/tracks/lines",
        image: lines

    },
    {
        id: 5,
        title: "The Fall (2022)",
        link: "/tracks/the%20fall",
        image: theFall

    },
]