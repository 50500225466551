import React, { useEffect, useState } from 'react';
import '../../styles/Photos Page/Photos.css';
import image1 from "../../assets/photos/ben1.jpg"
import image2 from "../../assets/photos/ben2.jpg"
import image3 from "../../assets/photos/ben3.jpg"
import image4 from "../../assets/photos/ben4.JPG"
import image5 from "../../assets/photos/ben5.JPG"
import image6 from "../../assets/photos/ben6.JPG"
import image7 from "../../assets/photos/ben7.JPG"
import image8 from "../../assets/photos/ben8.JPG"
import image9 from "../../assets/photos/ben9.JPG"
import image10 from "../../assets/photos/ben10.jpg"
import image11 from "../../assets/photos/ben11.JPG"
import image12 from "../../assets/photos/ben12.JPG"
import image13 from "../../assets/photos/ben13.JPG"
import image14 from "../../assets/photos/ben14.jpg"
import placeHolder from "../../assets/placeholder.png"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Helmet } from 'react-helmet-async';



const imageUrls = [

  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14

];

const Photos = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {

    const newItems = imageUrls.map((imageUrl, index) => ({
      id: index,
      imageUrl: imageUrl,
      colSpan: Math.random() < 0.5 ? 2 : 1,
    }));
    setItems(newItems);
  }, []);



  return (

    <div className="Photos">
      <Helmet>
        <title>Photos | Ben Scharlin</title>
        <meta name="description" content="Photos of Ben Scharlin" />
        <link rel="canonical" href='/photos' />
      </Helmet>
      <div className="photos-wrapper">
        <h1>Photos</h1>
        <a target="_blank" href='https://www.instagram.com/benscharlin/' rel="noreferrer">See more on Instagram</a>
        <div className="grid">
          {items.map((item) => (
            <div
              key={item.id}
              className={`item ${item.colSpan === 2 ? 'large-item' : ''}`}
            >
              <LazyLoadImage
                src={item.imageUrl}
                alt={`Image ${item.id + 1}`}
                className={item.colSpan === 2 ? 'large-image' : 'small-image'}
                effect="blur"
                placeholderSrc={placeHolder}
                loading='lazy'

              />
            </div>
          ))}
        </div>
      </div>
    </div>




  );
};

export default Photos;
