import React from "react";
import "../../styles/Films/Films.css"
import film from "../../assets/film.mp4"
import { Link } from "react-router-dom"
const Films = () => {
    return (
        <div className="Films">
            <h1>Some of Ben's Work</h1>
            <Link to="/work"><button>View More</button></Link>
            <div className="grid-container">
                <div className="grid-item"><div className="grid-item">
                    <div className="video-overlay">
                        <p>Lines</p>
                    </div>
                    <video src={film} autoPlay alt="" playsInline/>
                </div></div>
                <div className="grid-item"><div className="grid-item">
                    <div className="video-overlay">
                        <p>Lines</p>
                    </div>
                    <video src={film} autoPlay alt="" playsInline/>
                </div></div> <div className="grid-item"><div className="grid-item">
                    <div className="video-overlay">
                        <p>Lines</p>
                    </div>
                    <video src={film} autoPlay alt="" playsInline/>
                </div></div> <div className="grid-item"><div className="grid-item">
                    <div className="video-overlay">
                        <p>Lines</p>
                    </div>
                    <video src={film} autoPlay alt="" playsInline/>
                </div></div> <div className="grid-item"><div className="grid-item">
                    <div className="video-overlay">
                        <p>Lines</p>
                    </div>
                    <video src={film} autoPlay alt="" playsInline/>
                </div></div> <div className="grid-item"><div className="grid-item">
                    <div className="video-overlay">
                        <p>Lines</p>
                    </div>
                    <video src={film} autoPlay alt="" playsInline/>
                </div></div> <div className="grid-item"><div className="grid-item">
                    <div className="video-overlay">
                        <p>Lines</p>
                    </div>
                    <video src={film} autoPlay alt="" playsInline/>
                </div></div> <div className="grid-item"><div className="grid-item">
                    <div className="video-overlay">
                        <p>Lines</p>
                    </div>
                    <video src={film} autoPlay alt="" playsInline/>
                </div></div>

            </div>
           
        </div>
    )
}

export default Films