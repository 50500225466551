import React, { useEffect, useRef } from 'react';
import "../../styles/Home Page/About.css";
import wallpaper from "../../assets/ben-song.JPG"
import { motion, useAnimation } from 'framer-motion';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import placeHolder from "../../assets/placeholder.png"


const About = () => {

  const lineRef = useRef();
  const controls = useAnimation();

  // For page-number
  useEffect(() => {
    const element = lineRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            controls.start({ y: 0, opacity: 1 });
          } else {
            controls.start({ y: -50, opacity: 0 });
          }
        });
      },
      { threshold: 0.1 }
    );

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [controls]);


  return (
    <div className='About' ref={lineRef}>

      <div className="about__wrapper" >
        <motion.div initial={{ y: 0, opacity: 0 }}
          animate={controls}
          transition={{duration: 0.5}} className="about__image">
          <LazyLoadImage src={wallpaper} alt="wallpaper" effect='blur' placeholderSrc={placeHolder}/>

        </motion.div>
        <div className="about__content" >
          <div className="text-content "
          >
            <motion.h1
              initial={{ y: 0, opacity: 0 }}
              animate={controls}
              transition={{ duration: 0.5 }}>The Artist's Biography</motion.h1>

            <motion.p initial={{ y: 0, opacity: 0 }}

            
              animate={controls}
              transition={{ duration: 0.5 }}>Ben Scharlin is a multifaceted artist with a rich and diverse background. Born and raised in Berkeley, California, he has always been deeply connected to both his Californian roots and his Filipino heritage, frequently traveling and living between California and the Philippines, where his mother is originally from. This bicultural upbringing has profoundly influenced his artistic journey.

              
              
              
              
              </motion.p>
            
              <motion.p initial={{ y: 0, opacity: 0 }}
              animate={controls}
              transition={{ duration: 0.5 }}>Ben's music is a tapestry woven from the myriad of musical influences that have touched him throughout his life. His sound is a dynamic blend that spans genres such as Rock and Roll, Reggae, Folk, Funk, Electronic, and Indie. Each genre contributes to the distinct and vibrant musical experience he creates, reflecting his personal journey and the diverse environments he has been part of. Ben Scharlin’s story is one of perpetual exploration and fusion, continually shaped by his travels, experiences, and the rich cultural landscapes he navigates.
              </motion.p>
            <motion.p initial={{ y: 0, opacity: 0 }}


              animate={controls}
              transition={{ duration: 0.5 }}>From a young age, Ben was drawn to music, eventually becoming a singer, songwriter, and DJ. His passion for the arts didn't stop at music; he also ventured into the film industry, where he spent over two decades working both in front of and behind the camera. This extensive experience in filmmaking has given him a unique perspective and set of skills that he brings into his current creative endeavors.  </motion.p>
            <motion.p initial={{ y: 0, opacity: 0 }}
              animate={controls}
              transition={{ duration: 0.5 }}>In 2017, Ben made a significant life change by relocating to the Philippines, where he continues to live and thrive. There, he has expanded his artistic repertoire to include roles as a producer, director, and musician. His work in the Philippines has allowed him to immerse himself further into the local culture and music scene, enriching his already eclectic artistic style.</motion.p>
           

          </div>



        </div>


      </div>

    </div>
  );
}

export default About;
