import React, { useEffect, useRef, useState } from 'react'
import "../../styles/Home Page/Songs.css"
import { MdArrowRightAlt } from "react-icons/md";
import { motion, useAnimation } from 'framer-motion';
import { songs } from '../../data/Songs';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import placeHolder from "../../assets/placeholder.png"


const Songs = () => {
  const [hoveredSong, setHoveredSong] = useState(null);
  const lineRef = useRef();
  const controls = useAnimation();

  useEffect(() => {
    const element = lineRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            controls.start({ y: 0, opacity: 1 });
          } else {
            controls.start({ y: -50, opacity: 0 });
          }
        });
      },
      { threshold: 0.1 }
    );

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [controls]);

  return (
    <div className='Songs'>
      <div className="songs__wrapper" ref={lineRef}>
      <motion.h1 
          initial={{ y: -50, opacity: 0 }}
          animate={controls}
          transition={{duration: 1.5}}>Songs and Music</motion.h1>
        <div  initial={{ y: 0, opacity: 0 }}
          animate={controls}
          transition={{duration: 0.5}} className="songs">
        {songs?.map((song) => {
            return (
              <div className="song" key={song.id}>
                <div className="song-image-wrapper">
                  <LazyLoadImage src={song.image} alt={song.id} className="song-image" effect='blur' placeholderSrc={placeHolder} loading='lazy'/>
                  <div className="overlay" onMouseEnter={() => setHoveredSong(song.id)} onMouseLeave={() => setHoveredSong(null)}>
                  {hoveredSong === song.id && (
                  <div className="song-details">
                    <p>{song.title}</p>
                    <Link to={song.link}>
                      {' '}
                      <button>
                        Listen Now <span><MdArrowRightAlt /></span>
                      </button>
                    </Link>
                  </div>
                )}
                  </div>

                </div>
             
                
              </div>
            );
          })}

          


        </div>


      </div>








    </div>
  )
}

export default Songs