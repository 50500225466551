import gravitate from "../assets/songs/gravitate.jpg"
import likeLightning from "../assets/songs/like-lightning.jpeg"
import lines from "../assets/songs/lines.jpeg"
import theFall from "../assets/songs/the-fall.jpeg"
import time from "../assets/songs/time.jpeg"

import gravitateAudio from "../assets/audios/gravitate.mp3"
import likeLightningAudio from "../assets/audios/likeLightning.mp3"
import linesAudio from "../assets/audios/lines.mp3"
import theFallAudio from "../assets/audios/theFall.mp3"
import timeAudio from "../assets/audios/time.mp3"


const tracksData = [
    {
        title: 'Gravitate',
        artist: 'Ben Scharlin',
        image: gravitate,
        releaseDate: 'SEPTEMBER 1, 2023',
        audio: gravitateAudio,
        spotify: "https://open.spotify.com/track/0oGJSdzQkv7yrwBydGy9Iz",
        lyrics:[
            'Every day is just another day',
            'we can play a game',
            'still never awake',
            'I wanna fly like the evil flies',
            'Over all the lights that be believing',
            "They're awake every star",
            "You talk to we are so don't get far",
            "From the center you gravitate",
            "Gravitate, gravitate",
            "I wanna soar beyond the bottom",
            "of the mediocre forces in my way",
            "and pull the sword out from the stone",
            "in order to become the king",
            "of my own fate",
            "Every star you turn to we are so don't",
            "get far from the center where you",
            "Gravitate, gravitate, gravitate",
            "(Ooh, ooh, ooh, ooh)",
            "I wanna hand music weapons to depend",
            "against the man the I've",
            "pretended ot be my enemy",
            "but has my place take your body hand",
            "to the promised land make your mother's",
            "and give my brother all this earth, land, and space",
            "Every star you turn to we are so",
            "don't get far from the center where you",
            "Gravitate, gravitate, gravitate, gravitate, gravitate"
        ]
    },
    {
        title: 'Like Lightning',
        artist: 'Ben Scharlin',
        image: likeLightning,
        releaseDate: 'SEPTEMBER 9, 2022',
        audio: likeLightningAudio,
        spotify: "https://open.spotify.com/track/3PPPiBid08zzxgUD6phC7n",
        lyrics: [
            'I think I lost my mind',
            'Can you help me find',
            'My way?',
            'I wander through the night',
            'Waiting for the light',
            'Of day',
            'Of day',
            "I'm holding onto love",
            "And it's blinding",
            "It's blinding",
            "You struck me from above",
            'Like lightning',
            'Like lightning',
            'Like lightning',
            "And now I'm on my own",
            "I don't know whether to go",
            "Or stay",
            "You said you'd be my friend",
            "Be there till the end",
            "You'd say",
            "You'd say",
            "Everywhere I go, I just can't stay",
            "'Cause I can't wait",
            "'Cause I can't wait",
            "And everywhere I go, I see your face",
            "But it's too late",
            "It's too late",
            "It's too late",
            "I'm holding onto love",
            "And it's blinding",
            "It's blinding",
            "You struck me from above",
            'Like lightning',
            'Like lightning',
            "(Oh)",
            "I'm holding onto love",
            "And it's blinding",
            "It's blinding",
            "(Oh)",
            "You struck me from above",
            'Like lightning',
            'Like lightning',
            'Like lightning',

        ]
    },
    {
        title: 'Lines',
        artist: 'Ben Scharlin',
        image: lines,
        releaseDate: 'JULY 8, 2022',
        audio: linesAudio,
        spotify: "https://open.spotify.com/track/6huDfc1r9cDc6l0t6tyD7O",
        lyrics: [
            "Long time",
            "They′ve been pushing me on the lines",
            "All the way, it's breaking me down",
            "I spent time in the shadows of a hollow mind",
            "And I can tell you what I found", "What I found", "I can escape you now", "I can escape this feeling", "I can navigate the tunnels and your caves", "I can escape you now", "I′ll be fine, just as long as I take my time", "Plant both feet burning on the ground", "Read wide, grab hold on the hands of time", "To the place where I am found", "I am found", "I can escape you now", "I can escape this feeling", "I can navigate your tunnels and your caves", "I can escape you now", "You now", "Don't you care?", "Don't you care?", "Don′t you care?", "Don′t you care?", "Don't you care?", "Don′t you care?", "Don't you care?", "Long time", "They′ve been pushing me on the lines", "All the way, it's breaking me down", "I spent time in the shadows of a hallow mind", "And I can tell you what I found", "I can tell you what I found"
        ]
    },
    {
        title: 'The Fall',
        artist: 'Ben Scharlin',
        image: theFall,
        releaseDate: 'DECEMBER 2, 2022',
        spotify: "https://open.spotify.com/track/0CSyZXmSButC1zCIAN7niS",
        audio: theFallAudio,
        lyrics:[
            'Lyrics coming soon.',
           
        ]
    },
    {
        title: 'Time',
        artist: 'Ben Scharlin',
        image: time,
        releaseDate: 'MAY 5, 2023',
        audio: timeAudio,
        spotify: "https://open.spotify.com/track/7HP0hnLSAP1ScYeFQfayd0",
        lyrics:[
            'Lyrics coming soon.',
        ]
    },

]

export default tracksData