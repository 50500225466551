import ReactModal from 'react-modal';
import React, { useState } from 'react';
import "../styles/Modal.css"
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { RxCross2 } from "react-icons/rx";
import CustomForm from './CustomForm';




const Modal = () => {
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(true)

    const postUrl = `https://gmail.us17.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`
    
  

    return (
        <ReactModal isOpen={isLoginModalOpen}
            onRequestClose={() => setIsLoginModalOpen(false)}
            className="modal"
            overlayClassName="modal-overlay">
            <RxCross2 className='cross' onClick={() => setIsLoginModalOpen(false)} />
            <h1>Subscribe</h1>
            

            <MailchimpSubscribe url={postUrl}
                render={({ subscribe, status, message }) => (
                    <CustomForm
                        status={status}
                        message={message}
                        onValidated={formData => subscribe(formData)}
                    />
                )} />
        </ReactModal>
    )
}

export default Modal