import React from "react";
import "../styles/Sidebar.css"
import { Link } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";


const Sidebar = ({ isBurgerClicked, setIsFilmExpanded, setIsMusicExpanded }) => {
    return (
        <div className={isBurgerClicked ? "Sidebar" : "Sidebar hidden"}>
            <div>
                <Link to="/" onClick={() => {
                    setIsMusicExpanded(false)
                    setIsFilmExpanded(false)
                }}><h1>Home</h1></Link>
                <ul>
                    <li><h1>Music <span><IoIosArrowDown/></span></h1></li>
                    <li><Link to="/shows">Shows</Link></li>
                    <li><Link to="/photos">Photos</Link></li>
                    <li><Link to="/tracks">Tracks</Link></li>
                </ul>
                
                <ul>
                    <li><h1>Films <span><IoIosArrowDown/></span></h1></li>
                    <li><Link to="/work">Film Works</Link></li>
                    <li><Link to="/directed">Directed</Link></li>
                    <li><Link to="/produced">Produced</Link></li>
                    <li><Link to="/short-films">Short Films</Link></li>
                    <li><Link to="/music-videos">Music Videos</Link></li>
                  
                </ul>
            </div>


        </div>
    )
}


export default Sidebar