import React, { useEffect } from 'react';
import "../../styles/Home Page/Home.css"
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
import { Helmet } from 'react-helmet-async';


const Home = ({ calculateOverallProgress, progress, setProgress, setIsImageLoaded, isMusicExpanded, setIsMusicExpanded, setIsFilmExpanded, isFilmExpanded }) => {



  const handleExploreMusicClick = () => {
    setIsMusicExpanded(!isMusicExpanded);
    setIsFilmExpanded(false);
  };

  const handleExploreFilmClick = () => {
    setIsFilmExpanded(!isFilmExpanded);
    setIsMusicExpanded(false);
  };



  useEffect(() => {
    let str = isMusicExpanded || isFilmExpanded ? "" : "BEN SCHARLIN - MUSIC - FILMS -";
    const container = document.getElementById('logo');

    for (let i = 0; i < str.length; i++) {
      const span = document.createElement("span");
      span.innerHTML = str[i];
      container.appendChild(span);
      span.style.transform = `rotate(${11 * i}deg)`;
    }

    return () => {
      while (container?.firstChild) {
        container.removeChild(container?.firstChild);
      }
    };
  }, [isMusicExpanded, isFilmExpanded]);

  // Check if both images have been fully loaded
  // useEffect(() => {
  //   const loadImage = (selector, type) => {
  //     const imgElement = document.querySelector(selector);
  //     if (!imgElement) {
  //       console.error(`Element ${selector} not found.`);
  //       return;
  //     }

  //     const backgroundImage = window.getComputedStyle(imgElement).backgroundImage;
  //     const urlMatch = /url\((['"]?)(.*?)\1\)/.exec(backgroundImage);

  //     if (urlMatch && urlMatch[2]) {
  //       const img = new Image();
  //       img.src = urlMatch[2];

  //       img.onload = () => setIsImageLoaded(prevState => ({ ...prevState, [type]: true }));
  //       img.onerror = () => console.error(`Background image for ${type} failed to load`);
  //     } else {
  //       console.error(`No background image found for ${type} or incorrect format.`);
  //     }
  //   };

  //   loadImage('.main-song-wrapper', 'music');
  //   loadImage('.main-film-wrapper', 'film');
  // }, []);

  


  const loadImage = (selector, type) => {
    const imgElement = document.querySelector(selector);
    if (!imgElement) {
      console.error(`Element ${selector} not found.`);
      return;
    }

    const backgroundImage = window.getComputedStyle(imgElement).backgroundImage;
    const urlMatch = /url\((['"]?)(.*?)\1\)/.exec(backgroundImage);

    if (urlMatch && urlMatch[2]) {
      const imgURL = urlMatch[2];
      const xhr = new XMLHttpRequest();

      xhr.open('GET', imgURL, true);
      xhr.responseType = 'blob';

      xhr.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          setProgress((prevState) => {
            const newProgress = { ...prevState, [type]: percentComplete };
            calculateOverallProgress(newProgress);
            return newProgress;
          });
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          const blob = new Blob([xhr.response], { type: 'image/jpeg' }); // or 'image/png'
          const objectURL = URL.createObjectURL(blob);
          const img = new Image();
          img.src = objectURL;

          img.onload = () => {
            setIsImageLoaded(prevState => ({ ...prevState, [type]: true }));
            imgElement.style.backgroundImage = `url(${objectURL})`;
          };
        }
      };

      xhr.onerror = () => console.error(`Background image for ${type} failed to load`);
      xhr.send();
    } else {
      console.error(`No background image found for ${type} or incorrect format.`);
    }
  };

  useEffect(() => {
    loadImage('.main-song-wrapper', 'music');
    loadImage('.main-film-wrapper', 'film');
  }, []);
 




  return (
    <div className={isFilmExpanded ? "film__wrapper" : "music__wrapper"}>
      <Helmet>
        <title>Home | Ben Scharlin</title>
        <meta name="description" content="Ben Scharlin's Home Page" />
        <link rel="canonical" href='/' />
      </Helmet>

      {isMusicExpanded || isFilmExpanded ? <div className="name__wrapper">
        <h1>Ben</h1>
        <h1>•</h1>
        <h1>Scharlin</h1>
      </div> : ""}


      <div className={isMusicExpanded ? 'Home music' : isFilmExpanded ? 'Home film' : 'Home'}>
        <div className="logo-wrapper">
          <div id={isMusicExpanded || isFilmExpanded ? 'logo hidden' : 'logo'}></div>
        </div>
        <div className="main-wrapper">
          <div className={isMusicExpanded ? 'main-song-wrapper expanded' : 'main-song-wrapper' && isFilmExpanded ? "main-song-wrapper hidden" : 'main-song-wrapper'}>
            <h1 className={isFilmExpanded ? 'main-name hidden' : 'main-name'}>Music</h1>
            <button className={isFilmExpanded ? 'hidden' : ''} onClick={handleExploreMusicClick}><span><FaLongArrowAltLeft /></span>{isMusicExpanded ? 'Back Home' : 'Explore Music'}</button>
          </div>
          <div className={isMusicExpanded ? 'main-film-wrapper hidden' : 'main-film-wrapper' && isFilmExpanded ? "main-film-wrapper expanded" : 'main-film-wrapper'}>
            <h1 className={isMusicExpanded ? 'main-name hidden' : 'main-name'}>Films</h1>
            <button className={isMusicExpanded ? 'hidden' : ''} onClick={handleExploreFilmClick}>{isFilmExpanded ? 'Back Home' : 'Explore Films'}<span><FaLongArrowAltRight /></span></button>
          </div>
        </div>
      </div>
      {isMusicExpanded || isFilmExpanded ? <div className="name__wrapper">
        <p>Music Artist / Film Maker</p>
        <p>Ben Scharlin 2024</p>

      </div> : ""}
    </div>
  );
};

export default Home;
