import React from "react";
import "../../styles/Films/MusicVideos.css"
import film from "../../assets/film.mp4"
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const MusicVideos = () => {
    return (
        <div className="MusicVideos">
            <Helmet>
                <title>Music Videos | Ben Scharlin</title>
                <meta name="description" content="All of Ben Scharlin's music videos." />
                <link rel="canonical" href='/music-videos' />
            </Helmet>
            <div className="video-content">
                <h1>Music Videos</h1>
                <Link to ="/work">Back home</Link>
                <div className="category-grid-container">
                <div className="grid-item"><div className="grid-item">
                    <div className="video-overlay">
                        <p>Lines</p>
                    </div>
                    <video src={film} autoPlay alt="" playsInline/>
                </div></div>
                <div className="grid-item"><div className="grid-item">
                    <div className="video-overlay">
                        <p>Lines</p>
                    </div>
                    <video src={film} autoPlay alt="" playsInline/>
                </div></div>
                </div>
            </div>
        </div>
    )
}

export default MusicVideos